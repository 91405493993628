import React from "react";
import "./Presentation.css";
import Presentationimg from "../../../Assets/logoJ.svg";

function Presentation() {
  return (
    <section className="presentation-box">
      <div className="presentation-img">
        <img src={Presentationimg} alt="Logo de MWN-TECH" />
      </div>

      <div className="presentation-text">
        <h1>À propos de MWN-TECH : </h1>
        <p>
          Je m'appelle Merwan LAOUINI, étudiant en informatique et entrepreneur
          indépendant basé à Sèvres. MWN-TECH est le projet de développement web
          qui me passionne. Je me spécialise dans la création de sites web et
          d'applications web sur mesure, conçus pour répondre aux exigences
          spécifiques de chaque client. Je travaille en étroite collaboration
          avec mes clients pour transformer leurs idées en réalités numériques
          innovantes.
        </p>
        <p>
          Pour moi, un site web réussi doit être esthétiquement plaisant tout en
          offrant d'excellentes performances. C'est pourquoi je conçois des
          sites web qui sont non seulement visuellement attrayants, mais
          également optimisés pour la vitesse, la sécurité et l'expérience
          utilisateur. Que vous ayez besoin d'un simple site vitrine, d'un site
          e-commerce ou du développement d'une application web complexe, je mets
          mon expertise en développement web à votre disposition pour vous
          fournir un produit numérique de haute qualité qui répondra à vos
          attentes.
        </p>
      </div>
    </section>
  );
}

export default Presentation;
