import React from "react";
import MobileOnProdimg from "../../../Assets/mobiledev2.jpg";
import "./OnProd.css";

const MobileOnProd = () => {
  return (
    <div className="MobileOnProd-box">
      <div className="MobileOnProd-img">
        <img src={MobileOnProdimg} alt="MobileOnProd MWN-TECH" />
      </div>

      <div className="MobileOnProd-text">
        <h1> Test et mise en production : : </h1>
        <p>
          Avant le lancement, je m'engage dans une phase de test rigoureuse pour
          m'assurer que votre application fonctionne parfaitement sur différents
          appareils et systèmes d'exploitation. Cela comprend également la
          vérification des fonctionnalités, de la navigation et de l'expérience
          utilisateur. Si un problème est détecté, il est immédiatement corrigé.
          Après ces tests approfondis, et une fois que vous êtes pleinement
          satisfait, l'application est lancée sur les plateformes appropriées.
          Je vous accompagne lors de cette étape importante, assurant un
          lancement en douceur et une transition réussie vers votre nouvelle
          présence mobile.
        </p>
      </div>
    </div>
  );
};

export default MobileOnProd;
