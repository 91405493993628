import React from "react";
import "./Footer.css";
import logo from "../../../Assets/logoJ.svg";

function Footer() {
  return (
    <>
      <div className="footer-container">

        <img src={logo} alt="logo" />

        <p>© 2023 MWN-TECH. Tous droits réservés.</p>

      </div>
      
    </>
  );
}

export default Footer;
