import React from "react";
import interviewimg from "../../../Assets/interview.jpg";
import "./Mobileinterview.css";

const MobileInterview = () => {
  return (
    <div className="interview-box">
      <div className="interview-img">
        <img src={interviewimg} alt="interview MWN-TECH" />
      </div>

      <div className="interview-text">
        <h1>Consultation initiale : </h1>
        <p>
          L'aventure commence par une consultation approfondie, durant laquelle
          nous discutons de vos besoins, de vos objectifs et de vos préférences
          en matière de design pour l'application mobile. Cela me permet de
          comprendre votre vision et d'identifier exactement ce que vous
          recherchez dans votre application.
        </p>
      </div>
    </div>
  );
};

export default MobileInterview;
