import React from 'react';
import './Header.css';
import logoJ from '../../../Assets/logoJ.svg'; 

function Header() {
  return (
    <div className="header">
     <img src={logoJ} alt="logo" className='logo-img'/> 
    </div>
  );
}

export default Header;
