import React from "react";
import { Helmet } from "react-helmet";
import "./Home.css";
import { useEffect } from "react";
import { Analytics } from '@vercel/analytics/react';
import ReactGA from "react-ga";
import Header from "../../Components/All-Page/Header/Header";
import Title from "../../Components/Home/Title/Title";
import Presentation from "../../Components/Home/Presentation/Presentation";
import OurServices from "../../Components/Home/Our-service/Our-Service";
import Reference from "../../Components/Home/References/Reference";
import OurProjects from "../../Components/Home/Our-projects/Our-Projects";
import Localisation from "../../Components/Home/Localisation/Localisation";
import ContactForm from "../../Components/Home/Contact-Form/Contact-Form";
import Footer from "../../Components/All-Page/Footer/Footer";
import logoj from "../../Assets/logoJ.svg";

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)

    ReactGA.initialize(process.env.REACT_APP_GA );
    console.log("GA ID:", process.env.REACT_APP_GA);



    ReactGA.pageview(window.location.pathname + window.location.search);
    
  });
  return (
    <div className="App">
      <Helmet>
        <title>MWN-TECH</title>
        <link rel="icon" href={logoj} />
      </Helmet>
      <div>
        <Header />
      </div>
      <div>
        <Title />
      </div>
      <div>
        <Presentation />
      </div>
      <div>
        <OurServices />
      </div>
      <div>
        <Reference />
      </div>
      <div>
        <OurProjects />
      </div>
      <div>
        <Localisation />
      </div>
      <div>
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
      <Analytics />
    </div>
  );
}

export default Home;
