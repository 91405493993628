import React from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import "./Localisation.css";

function Localisation() {
  const mapStyles = {        
    height: "100%",
    width: "100%"};
  
  const defaultCenter = {
    lat: 48.839695, lng: 2.2399123
  }

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    draggable: false,
    styles: [
      {
        featureType: "poi.business",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      }
    ]
  }
  
  return (
    <>
    <div className="map">
     <LoadScript
       googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
       <GoogleMap
         mapContainerStyle={mapStyles}
         zoom={13}
         center={defaultCenter}
         options={mapOptions}>
         <Marker position={defaultCenter}>
           <InfoWindow position={defaultCenter}>
             <div className="info-window-content">
               <h4>Mobilité</h4>
               <p>Je suis flexible pour des postes situés partout en Île-de-France, ainsi que pour des rôles entièrement en télétravail.</p>
             </div>
           </InfoWindow>
         </Marker>
       </GoogleMap>
     </LoadScript>
    </div>
    </>
  )
}

export default Localisation;
