import React from "react";
import MobilePlanificationimg from "../../../Assets/planification.jpg";
import "./Planification.css";

const MobilePlanification = () => {
  return (
    <div className="MobilePlanification-box">
      <div className="MobilePlanification-img">
        <img src={MobilePlanificationimg} alt="MobilePlanification MWN-TECH" />
      </div>

      <div className="MobilePlanification-text">
        <h1>Planification : </h1>
        <p>
          Après notre consultation initiale, j'utilise mon expertise pour
          concevoir un plan détaillé de votre future application. Ce plan couvre
          le parcours de l'utilisateur, l'interface utilisateur, les
          fonctionnalités clés, et la compatibilité avec les différents systèmes
          d'exploitation mobiles. En adoptant une approche de développement
          agile, je travaille en cycles itératifs, ce qui me permet d'ajuster et
          de perfectionner le plan en fonction de vos commentaires. Dès que vous
          êtes satisfait et que j'ai votre approbation, je passe à la phase de
          développement.
        </p>
      </div>
    </div>
  );
};

export default MobilePlanification;
