import React from "react";
import OnProdimg from "../../../Assets/devfinish.jpg";
import "./OnProd.css";

const OnProd = () => {
  return (
    <div className="OnProd-box">
      <div className="OnProd-img">
        <img src={OnProdimg} alt="OnProd MWN-TECH" />
      </div>

      <div className="OnProd-text">
        <h1>Test et lancement : </h1>
        <p>
          Avant le lancement, je m'engage dans une phase de test rigoureux pour
          m'assurer que votre site fonctionne parfaitement sur différents
          appareils et navigateurs. Cela comprend également la vérification des
          liens, la navigation, l'expérience utilisateur et la vitesse de
          chargement. Si un problème est détecté, il est immédiatement corrigé.
          Après ces tests approfondis, et une fois que vous êtes pleinement
          satisfait, le site est lancé. Je vous accompagne lors de cette étape
          importante, assurant un lancement en douceur et une transition réussie
          vers votre nouvelle présence en ligne.
        </p>
      </div>
    </div>
  );
};

export default OnProd;
