import React from "react";
import Devlopmentimg from "../../../Assets/devImg.jpg";
import "./Devlopment.css";

const Devlopment = () => {
  return (
    <div className="Devlopment-box">
      <div className="Devlopment-img">
        <img src={Devlopmentimg} alt="Devlopment MWN-TECH" />
      </div>

      <div className="Devlopment-text">
        <h1>Développement : </h1>
        <p>
          Une fois le plan approuvé, je commence la phase de développement.
          Grâce à ma maîtrise de divers langages de programmation, je construis
          votre site en fonction de vos besoins spécifiques. Durant cette étape,
          je privilégie une approche agile, ce qui signifie que je développe et
          teste des segments de travail en continu, permettant des ajustements
          et des améliorations en temps réel. Je reste en contact étroit avec
          vous tout au long de ce processus, assurant que le site se développe
          conformément à vos attentes.
        </p>
      </div>
    </div>
  );
};

export default Devlopment;
