import { Routes, Route } from "react-router-dom";
import Home from "./Views/Home/Home";
import WebConception from "./Views/Web-Conception/Web-Conception";
import MobileConception from "./Views/Mobile-Conception/Mobile-Conception";
import WebMaintenance from "./Views/Web-Maintenance/Web-Maintenance";


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/web-conception" element={<WebConception/>} />
        <Route path="/mobile-conception" element={<MobileConception/>} />
        <Route path="/maintenance" element={<WebMaintenance />} />
      </Routes>
    </div>
  );
}

export default App;
