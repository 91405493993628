import React from 'react';
import { Link } from 'react-router-dom'; 
import "./ReturnHome.css";

const ReturnHome = () => {
    return (
        <div className="ReturnHome-box">
            <Link to="/" className='css-button-sliding-to-bottom--green'>
                Retour à l'accueil
            </Link>
        </div>
    )
}

export default ReturnHome;
