import React from "react";
import "./Mobile-Conception.css";
import { useEffect } from "react";

import Headers from "../../Components/All-Page/Header/Header";
import Footer from "../../Components/All-Page/Footer/Footer";
import MobileInterview from "../../Components/Mobile-Conception/Interview/interview";
import ReturnHome from "../../Components/All-Page/ReturnHome/ReturnHome";
import MobilePlanification from "../../Components/Mobile-Conception/Planification/Planification";
import MobileDev from "../../Components/Mobile-Conception/Devlopment/Devlopment";
import MobileOnProd from "../../Components/Mobile-Conception/OnProd/OnProd";
import { Helmet } from "react-helmet";
import logoj from "../../Assets/logoJ.svg";
import { Analytics } from '@vercel/analytics/react';
const MobileConception = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    
  });
  return (
    <div className="App">
      <Helmet>
        <title>MWN-TECH Devlopment Mobile</title>
        <link rel="icon" href={logoj} />
      </Helmet>

      <div>
        <Headers />
      </div>

      <div>
        <MobileInterview />
      </div>

      <div>
        <MobilePlanification />
      </div>

      <div>
        <MobileDev />
      </div>

      <div>
        <MobileOnProd />
      </div>

      <div>
        <ReturnHome />
      </div>

      <div>
        <Footer />
      </div>
      <Analytics />
    </div>
  );
};

export default MobileConception;
