import React from "react";
import "./Title.css";

function Title() {
  return (
    <div className="introduction">
      <div className="introduction-text">
        <div className="text">
          <h2>Merwan LAOUINI</h2>
          <h1>Développeur Web et Mobile Full-Stack </h1>
        </div>
        <div>
          <h3 className="text2">
                Spécialisé en ReactJS, React Native et NodeJS
          </h3>
        </div>
        
      </div>
    </div>
  );
}

export default Title;
