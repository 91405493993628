import React from "react";
import "./Web-Maintenance.css";
import { useEffect } from "react";
import { Analytics } from '@vercel/analytics/react';
import Headers from "../../Components/All-Page/Header/Header";
import Footer from "../../Components/All-Page/Footer/Footer";
import MaintenanceWeb from "../../Components/Maintenance/MaintenanceWeb/MaintenanceWeb";
import ReturnHome from "../../Components/All-Page/ReturnHome/ReturnHome";
import logoj from "../../Assets/logoJ.svg";
import { Helmet } from "react-helmet";


const WebMaintenance = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    
  });
  return (
    <div className="App">
      <Helmet>
        <title>MWN-TECH Maintenance Web</title>
        <link rel="icon" href={logoj} />
      </Helmet>
      <div>
        <Headers />
      </div>

      <div>
        <MaintenanceWeb />
      </div>

      <div>
        <ReturnHome />  
      </div>

      <div>
        <Footer />
      </div>
      <Analytics />
    </div>
  );
};

export default WebMaintenance;
