import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

import ReactGA from "react-ga";

// Initialisez Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA);

const AppWithAnalytics = () => {
  // Enregistrez une vue de page à chaque changement de route
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
    <Analytics />
  </Router>
);

ReactDOM.render(<AppWithAnalytics />, document.getElementById('root'));
reportWebVitals();
