import React, { useState } from "react";
import "./Contact-Form.css";
import emailjs from 'emailjs-com'; 

function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
    
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, {
            from_name: name,
            to_name: 'Merwan',
            message: `Email du client : ${email}\n\nMessage du client : ${message}`,
            reply_to: email
        }, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert("Email successfully sent!");
        }, (err) => {
            console.log('FAILED...', err);
            alert("Failed to send email. See console for more details.");
        });
    
        setName('');
        setEmail('');
        setMessage('');
    };
    

    return (
        <div className="contact-container">
            <div className="contact-text">
                <h1>Contactez-moi</h1>
                <p>Si vous avez des questions ou si vous souhaitez discuter d'un projet, n'hésitez pas à me contacter via ce formulaire. Je me ferai un plaisir de vous répondre dans les plus brefs délais.</p>
            </div>
            <div className="contact-form">
                <form onSubmit={handleSubmit}> {/* Ajout du gestionnaire d'événements onSubmit */}
                    <div className="form-row">
                        <div className="name-inputs">
                            <input type="text" placeholder="Votre nom" required value={name} onChange={handleNameChange} /> {/* Ajout des props value et onChange */}
                        </div>
                        <input type="email" placeholder="Votre email" required value={email} onChange={handleEmailChange} /> {/* Ajout des props value et onChange */}
                    </div>
                    <textarea placeholder="Votre message" required value={message} onChange={handleMessageChange}></textarea> {/* Ajout des props value et onChange */}
                    <button type="submit">Envoyer</button>
                </form>
            </div>
        </div>
    )
}

export default ContactForm;
