import React from "react";
import "./Web-Conception.css";
import { useEffect } from "react";
import { Analytics } from '@vercel/analytics/react';
import Headers from "../../Components/All-Page/Header/Header";
import Footer from "../../Components/All-Page/Footer/Footer";
import Interview from "../../Components/Web-Conception/interview/Interview";
import Planification from "../../Components/Web-Conception/Planification/Planification";
import Devlopment from "../../Components/Web-Conception/Devlopment/Devlopment";
import OnProd from "../../Components/Web-Conception/OnProd/OnProd";
import ReturnHome from "../../Components/All-Page/ReturnHome/ReturnHome";
import { Helmet } from "react-helmet";
import logoj from "../../Assets/logoJ.svg";

const WebConception = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    
  });
  return (
    <div className="App">
      <Helmet>
        <title>MWN-TECH Devloppement Web</title>
        <link rel="icon" href={logoj} />
      </Helmet>
      <div>
        <Headers />
      </div>

      <div>
        <Interview />
      </div>

      <div>
        <Planification />
      </div>

      <div>
        <Devlopment />
      </div>

      <div>
        <OnProd />
      </div>

      <div>
        <ReturnHome />
      </div>

      <div>
        <Footer />
      </div>
      <Analytics />
    </div>
  );
};

export default WebConception;
