import React from "react";
import MobileDevimg from "../../../Assets/mobiledev.jpg";
import "./Devlopment.css";

const MobileDev = () => {
  return (
    <div className="MobileDev-box">
      <div className="MobileDev-img">
        <img src={MobileDevimg} alt="MobileDev MWN-TECH" />
      </div>

      <div className="MobileDev-text">
        <h1>Développement : </h1>
        <p>
          Une fois le plan approuvé, je commence la phase de développement.
          Grâce à ma maîtrise de divers langages de programmation pour le
          développement mobile, je construis votre application en fonction de
          vos besoins spécifiques. Durant cette étape, je privilégie une
          approche agile, ce qui signifie que je développe et teste des segments
          de travail en continu, permettant des ajustements et des améliorations
          en temps réel. Je reste en contact étroit avec vous tout au long de ce
          processus, assurant que l'application se développe conformément à vos
          attentes.
        </p>
      </div>
    </div>
  );
};

export default MobileDev;
