import React from "react";
import "./Our-Projects.css";
import WorkR from "../../../Assets/Workr.png";
import Shothesapp from "../../../Assets/Shothes-app.png";
import Sipp from "../../../Assets/SIPP.jpeg";

function OurProjects() {
  return (
    <>
      <div className="projects-title">
        <h1>Nos Réalisations</h1>
        <p class="project-intro">
          Voici quelques-uns de mes projets les plus récents et les plus
          passionnants
        </p>
      </div>
      <div className="projects">
        <div className="projects-boxes">
          <div className="projects-box">
            <a
              href="https://github.com/fayssalmechmeche/Projet-Work-r"
              target="_blank"
              rel="noopener noreferrer"
              title="Projet Workr"
            >
              <img src={WorkR} alt="Capture d'écran du projet Workr" />
            </a>
          </div>
          <div className="projects-box">
            <a
              href="https://github.com/AxelDemorest/shothes-app"
              target="_blank"
              rel="noopener noreferrer"
              title="Projet Shothes App"
            >
              <img src={Shothesapp} alt="Capture d'écran de Shothes App" />
            </a>
          </div>
          <div className="projects-box">
            <a
              href="https://www.sippgroupe.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="Projet SIPP"
            >
              <img src={Sipp} alt="Capture d'écran du site Web SIPP" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurProjects;
