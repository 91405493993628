import React from "react";
import "./Our-Service.css";
import { Link } from "react-router-dom";
import Imgweb from "../../../Assets/Presentation.jpg";
import Imgmaintenance from "../../../Assets/maintenance.jpg";
import Imgmobile from "../../../Assets/mobiledev.jpg";

function OurServices() {
  return (
    <div className="service">
      <div className="service-boxes">
        <div className="service-box">
          <img
            src={Imgweb}
            alt="Conception de site web"
            className="service-box-img"
          />
          <h2>Conception de sites web</h2>
          <p>
            Nous concevons des sites web personnalisés qui correspondent à vos
            besoins et reflètent l'identité de votre entreprise.
          </p>
          <Link to="/web-conception" className="css-button-sliding-to-bottom--green">En savoir plus</Link>
        </div>


        <div className="service-box">
          <img
            src={Imgmobile}
            alt="Conception d'application mobile"
            className="service-box-img"
          />
          <h2>Conception d'applications mobile</h2>
          <p>
            Nous concevons des applications mobile personnalisés qui correspondent à vos
            besoins et reflètent votre entreprise.
          </p>
          <Link to="/mobile-conception" className="css-button-sliding-to-bottom--green">En savoir plus</Link>
        </div>

       


        <div className="service-box">
          <img
            src={Imgmaintenance}
            alt="Maintenance de site et d'application mobile"
            className="service-box-img"
          />
          <h2>Maintenance de site et d'application mobile</h2>
          <p>
            Nous assurons la maintenance régulière de votre site web pour
            garantir son bon fonctionnement et sa sécurité.
          </p>
          <Link to="/maintenance" className="css-button-sliding-to-bottom--green">En savoir plus</Link>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
