import React from "react";
import MaintenanceWebimg from "../../../Assets/MaintenanceWeb.png";
import "./MaintenanceWeb.css";

const MaintenanceWeb = () => {
  return (
    <div className="MaintenanceWeb-box">
      <div className="MaintenanceWeb-img">
        <img src={MaintenanceWebimg} alt="MaintenanceWeb MWN-TECH" />
      </div>

      <div className="MaintenanceWeb-text">
        <h1>Maintenance et support : </h1>
        <p>
          Une fois votre site web ou votre application mobile lancé, mon
          engagement envers vous et votre projet perdure selon la prestation
          choisi. Je sais à quel point une maintenance et un soutien réguliers
          sont essentiels pour garantir la fluidité de votre présence numérique
          et pour répondre aux attentes toujours croissantes de vos
          utilisateurs.
        </p>

        <p>
          Dans le cadre de mes services de maintenance, je m'occupe de la
          résolution proactive des problèmes techniques, de l'optimisation
          continue pour améliorer les performances, ainsi que des mises à jour
          régulières du contenu ou des fonctionnalités pour rester au fait des
          tendances du marché et de vos besoins changeants. Que vous ayez besoin
          d'ajuster une fonctionnalité existante ou d'en introduire une nouvelle
          pour améliorer l'expérience utilisateur, je suis là pour vous aider.
        </p>

        <p>
          Au-delà de la maintenance, je vous offre un soutien dédié et réactif
          pour toutes vos préoccupations techniques. Je m'engage à répondre
          rapidement à vos demandes et à apporter des solutions efficaces. Je
          reste en contact étroit avec vous, garantissant une communication
          transparente et un service d'assistance fiable. Mon objectif ultime
          est de vous aider à maintenir une présence numérique dynamique,
          pertinente et attrayante pour vos utilisateurs, aujourd'hui et à
          l'avenir.
        </p>
      </div>
    </div>
  );
};

export default MaintenanceWeb;
