import React from "react";
import "./Reference.css";

function Reference() {
  return (
    <div className="reference">
      <div className="references-boxes">
        <h1>
          "Nous ne devons pas seulement utiliser les outils, nous devons les
          inventer." 
        </h1>
        <h2>- Mark Zuckerberg</h2>
      </div>
    </div>
  );
}

export default Reference;
