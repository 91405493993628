import React from "react";
import Planificationimg from "../../../Assets/planification.jpg";
import "./Planification.css";

const Planification = () => {
  return (
    <div className="Planification-box">
      <div className="Planification-img">
        <img src={Planificationimg} alt="Planification MWN-TECH" />
      </div>

      <div className="Planification-text">
        <h1>Planification : </h1>
        <p>
          Après notre consultation initiale, j'utilise mon expérience pour
          concevoir un plan détaillé de votre futur site web. Ce plan couvre la
          structure du site, l'aspect visuel, les fonctionnalités essentielles
          et l'expérience utilisateur. En adoptant une approche de développement
          agile, je travaille en cycles itératifs, ce qui me permet d'ajuster et
          de perfectionner le plan en fonction de vos commentaires. Dès que vous
          êtes satisfait et que j'ai votre approbation, je passe à la phase de
          développement.
        </p>
      </div>
    </div>
  );
};

export default Planification;
