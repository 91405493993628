import React from "react";
import interviewimg from "../../../Assets/interview.jpg";
import "./WebInterview.css"

const Webinterview = () => {
  return (
    <div className="Web-interview-box">
      <div className="Web-interview-img">
        <img src={interviewimg} alt="Web-interview MWN-TECH" />
      </div>

      <div className="Web-interview-text">
        <h1>Consultation initiale : </h1>
        <p>
          Le début de notre collaboration est marqué par une consultation
          initiale exhaustive, une étape que nous considérons comme cruciale
          pour le succès de votre projet. Durant cette première rencontre, nous
          plongeons profondément dans l'univers de votre entreprise, en
          analysant ses particularités, sa position sur le marché, ainsi que vos
          valeurs et aspirations.
        </p>
      </div>
    </div>
  );
};

export default Webinterview;
